import { FC } from "react";

import * as S from "./style";

export const Header: FC = () => (
  <S.Header>
    <S.Container>
      <S.Link to="/">
        <picture>
          <source
            srcSet="/assets/img/logo-desktop.png"
            media="(min-width: 560px)"
          />
          <S.Logo className="logo" src="/assets/img/logo-mobile.png" alt="" />
        </picture>
      </S.Link>
    </S.Container>
  </S.Header>
);
