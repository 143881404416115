import { FC } from "react";

import * as S from "./style";
import { ButtonProps } from "./types";

export const Button: FC<ButtonProps> = ({
  children,
  onClick,
  disabled,
  theme = "default",
  size = "default",
  stretch,
  ...props
}) => (
  <S.Button
    onClick={onClick}
    disabled={disabled}
    theme={theme}
    size={size}
    stretch={stretch}
    {...props}
  >
    {children}
  </S.Button>
);
