import { FC, useEffect } from "react";

import { Button } from "@/shared/ui";

import * as S from "./style";

export const MainPage: FC = () => {
  useEffect(() => {
    document.querySelector("body")?.classList.add("main-page");

    return () => {
      document.querySelector("body")?.classList.remove("main-page");
    };
  }, []);

  const handleLearnMore = () => {
    window.open("http://screenshoter.plus/?utm_source=scr.sh");
  };

  return (
    <S.Root>
      <S.Content>
        <S.Title>Join 50,000+ users who use Screenpixel</S.Title>
        <S.Description>
          No more looking for two separate programs for creating screenshots and
          screen recording
        </S.Description>
      </S.Content>
      <S.Footer>
        <Button size="large" theme="gradient" onClick={handleLearnMore}>
          Learn more
        </Button>
      </S.Footer>
    </S.Root>
  );
};
