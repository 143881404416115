import { FC } from "react";

import * as S from "./style";
import { InputProps } from "./types";

export const Input: FC<InputProps> = ({
  value,
  onChange,
  placeholder,
  error,
}) => (
  <S.Input>
    <S.InputComponent
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      type="text"
      isError={!!error}
    />
    {!!error && <S.Error>{error}</S.Error>}
  </S.Input>
);
