import { styled } from "@/shared/lib/styles";

export const Input = styled("div");

export const InputComponent = styled("input", {
  width: "100%",
  padding: "10px 16px",

  backgroundColor: "transparent",
  border: "1px solid $textGray",
  borderRadius: "$default",
  outline: "none",

  fontSize: "$defaultSmall",

  transition: "$default",

  "&::placeholder": {
    color: "$textGray",
    fontSize: "$small",
    fontWeight: "$regular",
    lineHeight: "200%",
  },

  variants: {
    isError: {
      true: {
        borderColor: "$red",

        color: "$red",

        "&::placeholder": {
          color: "$red",
          opacity: 1,
        },
      },
    },
  },
});

export const Error = styled("span", {
  display: "block",

  marginTop: 6,

  color: "$red",
  fontSize: "$small",
  fontWeight: "$regular",
  lineHeight: "180%",
});
