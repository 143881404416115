import "normalize.css";
import "plyr-react/plyr.css";
import "@/shared/lib/styles/style.css";

import { setupGlobalStyles } from "@/shared/lib/styles";
import { Header } from "@/shared/ui";
import { Router } from "@/views";

import * as S from "./style";

export const App = () => {
  setupGlobalStyles();

  return (
    <div>
      <Header />

      <S.Main>
        <Router />
      </S.Main>

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          title="google tag manager"
          src="https://www.googletagmanager.com/ns.html?id=GTM-5CRSVZK"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
      {/* End Google Tag Manager (noscript) */}
    </div>
  );
};
