import { styled } from "@/shared/lib/styles";

export const Modal = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  position: "fixed",
  zIndex: "$modal",
  inset: 0,

  visibility: "hidden",
  opacity: 0,
  backgroundColor: "$overlay",
  cursor: "pointer",

  transition: "$default",

  variants: {
    isOpened: {
      true: {
        visibility: "visible",
        opacity: 1,
      },
    },
  },
});

export const Content = styled("div", {
  width: "100%",
  maxWidth: 600,
  margin: "0 15px",
  padding: 50,

  boxSizing: "border-box",
  transform: "translateY(10px)",
  backgroundColor: "$white",
  borderRadius: "$large",
  cursor: "default",

  transition: "$default",

  "@wideMobile": {
    padding: 60,
  },

  "@wideTablet": {
    padding: 80,
  },

  variants: {
    isOpened: {
      true: {
        transform: "translateY(0)",
      },
    },
  },
});

export const StatusIcon = styled("div", {
  display: "flex",
  justifyContent: "center",

  marginBottom: 15,

  "& > svg": {
    width: 40,
    height: 40,
  },

  "@wideMobile": {
    "& > svg": {
      width: 60,
      height: 60,
    },
  },
});

export const Title = styled("h3", {
  margin: 0,

  fontSize: "$smallLarge",
  fontWeight: "$medium",
  lineHeight: "160%",
  textAlign: "center",

  "@wideMobile": {
    fontSize: "$default",
  },
});
