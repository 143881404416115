import { globalCss } from "@stitches/react";

export const setupStyles = globalCss({
  html: {
    scrollBehavior: "smooth",
  },
  body: {
    backgroundColor: "$background",

    color: "$text",
    fontFamily: "$rubik",
    fontSize: "$default",
    fontWeight: "$medium",
    lineHeight: "24px",

    "&.overflow-hidden": {
      overflow: "hidden",
    },

    "&.main-page": {
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundImage: "url('/assets/img/home-bg.png')",
    },

    "& .grecaptcha-badge": {
      right: "-201px !important",
      bottom: "calc(150px - 50vh) !important",

      "&:hover": {
        right: "-15px !important",
      },

      "@media(min-width: 620px)": {
        right: "calc(100px - 50vw) !important",

        "&:hover": {
          right: "calc(300px - 50vw) !important",
        },
      },
    },
  },
  a: {
    cursor: "pointer",

    color: "inherit",
  },
  "*, ::before, ::after": {
    boxSizing: "border-box",
  },
  "h1, h2, h3, h4, h5, h6, p": {
    margin: 0,
  },
  ".visually-hidden": {
    position: "absolute",

    width: 1,
    height: 1,
    margin: -1,
    border: 0,
    padding: 0,

    whiteSpace: "nowrap",

    clipPath: "inset(100%)",
    clip: "rect(0 0 0 0)",
    overflow: "hidden",
  },
});
