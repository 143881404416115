import { styled } from "@/shared/lib/styles";

export const Root = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignSelf: "stretch",

  marginTop: 64,
  paddingBottom: 100,

  "@wideMobile": {
    display: "block",
    alignSelf: "center",

    marginTop: 0,
    padding: "15% 30px",

    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundImage: "url('/assets/img/home-content-bg.png')",
  },

  "@tablet": {
    padding: "100px 0",
  },

  "@wideTablet": {
    padding: "80px 100px",
  },
});

export const Content = styled("div");

export const Title = styled("h1", {
  margin: "0 0 20px",

  fontSize: "$default",
  fontWeight: "$bold",
  lineHeight: "28px",
  textAlign: "center",

  "@wideTablet": {
    marginBottom: 30,

    fontSize: "$large",
    lineHeight: "57px",
  },
});

export const Description = styled("p", {
  margin: "0 0 200px",

  color: "$textGray",
  fontSize: "$small",
  fontWeight: "$regular",
  lineHeight: "180%",
  textAlign: "center",

  "@wideMobile": {
    marginBottom: 30,
  },

  "@wideTablet": {
    marginBottom: 50,

    fontSize: "$defaultSmall",
    lineHeight: "200%",
  },
});

export const Footer = styled("div", {
  display: "flex",
  justifyContent: "center",
});
