import { styled } from "@/shared/lib/styles";
import { Modal } from "@/shared/ui/atoms/Modal/style";

export const Root = styled("div", {
  [`& > ${Modal} > div`]: {
    maxWidth: 900,
  },
});

export const ImageContent = styled("div", {
  marginBottom: 66,

  "@wideMobile": {
    marginBottom: 40,
  },

  "@wideTablet": {
    marginBottom: 50,
  },
});

export const ImageButton = styled("button", {
  display: "block",

  width: "fit-content",
  maxWidth: 925,
  maxHeight: 600,
  margin: "0 auto",
  padding: 0,

  filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))",
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
});

export const Image = styled("img", {
  display: "block",

  width: "100%",
  maxHeight: 600,

  objectFit: "contain",
});

export const PreviewImage = styled("img", {
  display: "block",

  width: "100%",
});

export const Footer = styled("div", {
  display: "flex",
  justifyContent: "center",
  gap: 30,
});

export const ActionIcon = styled("div", {
  display: "flex",
  justifyContent: "center",

  marginBottom: 30,

  "& > svg, & > img": {
    width: 50,
    height: 50,
  },

  "@wideMobile": {
    marginBottom: 40,

    "& > svg, & > img": {
      width: 60,
      height: 60,
    },
  },

  "@wideTablet": {
    marginBottom: 50,

    "& > svg, & > img": {
      width: 100,
      height: 100,
    },
  },
});

export const ActionImg = styled("img", {
  display: "block",
});

export const ActionTitle = styled("h1", {
  fontSize: "$default",
  fontWeight: "$medium",
  lineHeight: "28px",
  textAlign: "center",

  "@wideTablet": {
    fontSize: "$large",
    lineHeight: "57px",
  },
});
