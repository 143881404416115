import { styled } from "@/shared/lib/styles";

export const Button = styled("button", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 6,

  padding: "14px 28px",

  backgroundColor: "$pink",
  border: "none",
  borderRadius: "$default",
  cursor: "pointer",

  color: "$textWhite",
  fontSize: "$small",
  fontWeight: "$medium",
  lineHeight: "150%",
  textDecoration: "none",

  transition: "$cubic",

  "&:hover": {
    opacity: 0.8,
  },

  "&:disabled": {
    opacity: 0.6,
    cursor: "default",
  },

  variants: {
    size: {
      default: {},
      medium: {
        padding: "12px 24px",

        fontSize: "$smallMedium",

        "@wideMobile": {
          padding: "16px 54px",

          fontSize: "$defaultMedium",
        },
      },
      large: {
        padding: "14px 44px",

        fontSize: "$small",
        lineHeight: "150%",

        "@wideTablet": {
          padding: "24px 74px",

          fontSize: "$smallLarge",
        },
      },
    },
    theme: {
      default: {},
      gray: {
        backgroundColor: "$gray",
      },
      gradient: {
        background: "$pinkGradient",

        "&:hover:not(:disabled)": {
          opacity: 1,
          filter: "contrast(200%) saturate(45%)",
        },
      },
    },
    stretch: {
      true: {
        width: "100%",
      },
    },
  },
});
