import { Route as RouteType } from "@/shared/lib/types/Router";

import { ContentPage } from "./Content";
import { MainPage } from "./Main";
import { NotFoundPage } from "./NotFound";

export const routes: RouteType[] = [
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/:id",
    element: <ContentPage />,
  },
  {
    path: "/404",
    element: <NotFoundPage />,
  },
];
