import axios from "axios";
import axiosRetry from "axios-retry";

import { urls } from "@/api/urls";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // 60s
  timeout: 60000,
  validateStatus: (status) => status >= 200 && status < 300,
});

axiosRetry(api, {
  retries: 60,
  retryDelay: () => 5000,
  retryCondition: (error) => {
    const instanceUrl = urls.fileMetaById("0");

    return (
      error.response?.status !== 404 &&
      !!error.config?.url?.includes(
        instanceUrl.slice(0, instanceUrl.length - 2) || "",
      )
    );
  },
});
