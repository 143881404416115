import { styled } from "@/shared/lib/styles";

export const Main = styled("main", {
  display: "grid",
  alignItems: "flex-start",

  minHeight: "calc(100vh - 80px)",
  paddingTop: "34px !important",
  paddingBottom: "34px !important",

  boxSizing: "border-box",

  container: "",

  "@wideMobile": {
    paddingTop: "84px !important",
  },

  "@wideTablet": {
    minHeight: "calc(100vh - 135px)",
    paddingTop: "30px !important",
  },
});
