import { AxiosResponse } from "axios";

import { api as apiFetch } from "./base";
import { DeleteFileByIdParams, GetFileMetaResponse } from "./types";
import { urls } from "./urls";

export const api = {
  file: {
    getFileById: (id: string): Promise<AxiosResponse> =>
      apiFetch.get(urls.fileById(id), { responseType: "blob" }),
  },
  delete: {
    deleteFileById: ({
      fileId,
      userId,
      captcha,
    }: DeleteFileByIdParams): Promise<AxiosResponse> =>
      apiFetch.put(urls.deleteFileById(fileId), { userId, captcha }),
  },
  meta: {
    getFileMeta: (
      id: string,
      params?: Record<string, any>,
    ): Promise<AxiosResponse<GetFileMetaResponse>> =>
      apiFetch.get(urls.fileMetaById(id), { ...params }),
  },
};
