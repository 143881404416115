import { FC } from "react";

export const WarningRoundIcon: FC = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 50C6 55.7782 7.13809 61.4997 9.3493 66.8381C11.5605 72.1764 14.8015 77.0269 18.8873 81.1127C18.8912 81.1166 18.8951 81.1205 18.899 81.1244C26.8605 89.08 37.8557 94 50 94V100C47.9904 100 45.9869 99.8789 44 99.6387C39.4949 99.0941 35.0755 97.9377 30.8658 96.194C24.7996 93.6812 19.2876 89.9983 14.6447 85.3553C10.0017 80.7124 6.31876 75.2005 3.80602 69.1342C1.29329 63.0679 0 56.5661 0 50C0 43.4339 1.29329 36.9321 3.80602 30.8658C6.31876 24.7996 10.0017 19.2876 14.6447 14.6447C19.2876 10.0017 24.7995 6.31876 30.8658 3.80602C35.0755 2.06232 39.4949 0.905853 44 0.361305C45.9869 0.12114 47.9904 0 50 0V6C37.9715 6 27.0703 10.8267 19.1276 18.6489C19.0472 18.728 18.9671 18.8075 18.8873 18.8873C18.8155 18.9591 18.7439 19.0312 18.6726 19.1035C10.8364 27.0482 6 37.9593 6 50Z"
      fill="#7421B0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50 6V0C52.0076 0 54.0112 0.120909 56 0.361306C56.3511 0.403745 56.7018 0.449909 57.0519 0.499789C59.0352 0.782331 61.0018 1.18411 62.941 1.70371L61.3909 7.48872C57.7577 6.51768 53.9393 6 50 6ZM61.3909 92.5113L62.9409 98.2963L62.9595 98.2913C64.8922 97.7727 66.7901 97.139 68.643 96.3944C68.9711 96.2625 69.2979 96.1272 69.6232 95.9884C71.4636 95.2031 73.2573 94.3071 74.994 93.3047L75 93.3013L72.0037 88.1115C68.7119 90.0161 65.1506 91.5064 61.3909 92.5113ZM88.1115 72.0037C86.1798 75.3421 83.8221 78.4033 81.1127 81.1127L85.3553 85.3553C86.7749 83.9358 88.1062 82.4335 89.3425 80.8572C89.5608 80.5789 89.7761 80.2983 89.9884 80.0155C91.191 78.4133 92.2975 76.7386 93.3013 75L88.1115 72.0037ZM72.0037 11.8885C75.3421 13.8202 78.4033 16.1779 81.1127 18.8873L85.3553 14.6447C83.9358 13.2251 82.4335 11.8938 80.8572 10.6575C80.5789 10.4392 80.2983 10.2239 80.0155 10.0116C78.4152 8.8104 76.7425 7.70506 75.006 6.70219L75 6.69873L72.0037 11.8885ZM88.1115 27.9963C90.016 31.2881 91.5064 34.8494 92.5113 38.6091L98.2963 37.0591L98.2956 37.0563C97.7761 35.1181 97.141 33.215 96.3944 31.357C96.2625 31.0289 96.1272 30.7021 95.9884 30.3768C95.2021 28.5342 94.3051 26.7386 93.3013 25L88.1115 27.9963ZM94 50H100C100 52.0076 99.8791 54.0112 99.6387 56C99.5963 56.3511 99.5501 56.7018 99.5002 57.0519C99.2177 59.0352 98.8159 61.0018 98.2963 62.9409L92.5113 61.3909C93.4823 57.7577 94 53.9393 94 50Z"
      fill="#7421B0"
    />
    <rect x="47" y="26" width="6" height="36" rx="1" fill="#7421B0" />
    <rect x="47" y="68" width="6" height="6" rx="1" fill="#7421B0" />
  </svg>
);
