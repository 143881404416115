import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/shared/ui";

import * as S from "./style";

export const NotFoundPage: FC = () => {
  const navigate = useNavigate();

  return (
    <S.Root>
      <S.Content>
        <S.Title>404</S.Title>
        <S.Subtitle>Oops, page not found</S.Subtitle>
        <S.Description>You can go to the main page</S.Description>
      </S.Content>
      <S.Footer>
        <Button size="large" theme="gradient" onClick={() => navigate("/")}>
          Home
        </Button>
      </S.Footer>
    </S.Root>
  );
};
