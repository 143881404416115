import { FC, useEffect, useMemo, useRef } from "react";

import { CheckRoundIcon } from "@/shared/lib/icons";

import * as S from "./style";
import { StatusModalProps } from "./types";

export const StatusModal: FC<StatusModalProps> = ({
  isOpened,
  handleClose,
  status = "success",
  children,
}) => {
  const contentRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (isOpened) {
      document.body.classList.add("overflow-hidden");

      setTimeout(() => {
        handleClose();
      }, 2000);

      return;
    }

    document.body.classList.remove("overflow-hidden");
  }, [isOpened]);

  const icon = useMemo(() => {
    switch (status) {
      case "success":
        return <CheckRoundIcon />;
      default:
        return <CheckRoundIcon />;
    }
  }, [status]);

  return (
    <S.Modal
      isOpened={isOpened}
      onClick={({ target }) =>
        contentRef.current &&
        !contentRef.current?.contains(target as Node) &&
        handleClose()
      }
    >
      <S.Content isOpened={isOpened} ref={contentRef}>
        <S.StatusIcon>{icon}</S.StatusIcon>
        <S.Title>{children}</S.Title>
      </S.Content>
    </S.Modal>
  );
};
