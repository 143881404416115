import Plyr from "plyr-react";
import { FC } from "react";

import * as S from "./style";
import { VideoProps } from "./types";

export const Video: FC<VideoProps> = ({ options, source }) => (
  <S.Video>
    <Plyr source={source} options={options} />
  </S.Video>
);
