import { FC } from "react";

export const CheckRoundIcon: FC = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47 25C47 37.1503 37.1503 47 25 47C12.8497 47 3 37.1503 3 25C3 12.8497 12.8497 3 25 3C37.1503 3 47 12.8497 47 25ZM50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25ZM21.7783 33.2635L35.9205 19.1213L33.7991 17L19.657 31.1421L21.7783 33.2635ZM14 25.4853L16.1213 23.364L21.7782 29.0208L19.6569 31.1421L14 25.4853Z"
      fill="#7421B0"
    />
  </svg>
);
