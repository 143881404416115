import { styled } from "@/shared/lib/styles";

export const Modal = styled("div", {
  position: "fixed",
  zIndex: "$modal",
  inset: 0,

  overflowY: "auto",
  overflowX: "hidden",
  visibility: "hidden",
  opacity: 0,
  backgroundColor: "$overlay",
  cursor: "pointer",

  transition: "$default",

  variants: {
    isOpened: {
      true: {
        visibility: "visible",
        opacity: 1,
      },
    },
  },
});

export const ContentWrapper = styled("div", {
  width: "100%",
  maxWidth: 600,
  margin: "100px auto 20px",
});

export const Content = styled("div", {
  width: "calc(100% - 30px)",
  margin: "0 15px",
  padding: "20px 24px",

  boxSizing: "border-box",
  transform: "translateY(10px)",
  backgroundColor: "$white",
  borderRadius: "$large",
  cursor: "default",

  transition: "$default",

  "@wideMobile": {
    padding: 30,
  },

  variants: {
    isOpened: {
      true: {
        transform: "translateY(0)",
      },
    },
  },
});

export const Title = styled("h3", {
  margin: "0 0 20px",

  fontSize: "$smallLarge",
  fontWeight: "$medium",
  lineHeight: "160%",

  "@wideMobile": {
    marginBottom: 30,

    fontSize: "$default",
  },
});

export const ModalContent = styled("div");

export const Footer = styled("div", {
  display: "flex",
  gap: 30,

  marginTop: 25,

  "& > div, & > button": {
    width: "50%",
  },

  "@wideMobile": {
    justifyContent: "flex-end",

    marginTop: 30,

    "& > div, & > button": {
      width: "auto",
    },
  },

  "@wideTablet": {
    gap: 20,
  },
});
