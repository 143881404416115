import { createStitches } from "@stitches/react";

import { setupFonts } from "./fonts";
import { setupStyles } from "./global";

export const media = {
  wideMobile: "only screen and (min-width: 560px)",
  tablet: "only screen and (min-width: 768px)",
  wideTablet: "only screen and (min-width: 960px)",
  laptop: "only screen and (min-width: 1200px)",
};

export const { styled, keyframes } = createStitches({
  theme: {
    colors: {
      background: "#FFFFFF",
      gray: "#464646",
      pink: "#7421B0",
      red: "#F22F2F",
      white: "#FFFFFF",
      text: "#131313",
      textWhite: "#FFFFFF",
      textGray: "#828282",
      pinkGradient:
        "linear-gradient(97.02deg, #7421B0 39.81%, #D08EFF 139.98%)",
      overlay: "rgba(0, 0, 0, 0.3)",
    },
    fontSizes: {
      notFoundTitle: "100px",
      large: "48px",
      defaultLarge: "36px",
      default: "24px",
      defaultMedium: "20px",
      defaultSmall: "18px",
      smallLarge: "16px",
      smallMedium: "14px",
      small: "12px",
    },
    fontWeights: {
      regular: 400,
      medium: 500,
      bold: 700,
    },
    fonts: {
      rubik: "Rubik, sans-serif",
    },
    radii: {
      default: "5px",
      large: "10px",
    },
    transitions: {
      default: "0.2s ease-in-out",
      cubic: "all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5)",
    },
    zIndices: {
      header: 100,
      modal: 50,
    },
  },
  media,
  utils: {
    container: () => ({
      width: "100%",
      maxWidth: 1200,
      margin: "0 auto",
      padding: "0 15px",

      "@wideMobile": {
        padding: "0 50px",
      },

      "@wideTablet": {
        padding: "0 20px",
      },
    }),
  },
});

export const setupGlobalStyles = () => {
  setupFonts();
  setupStyles();
};
