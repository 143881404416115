import { FC, useEffect, useRef } from "react";
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { useParams } from "react-router-dom";

import { ContentStatus, ContentType } from "@/shared/lib/types";
import { Button, Input, Modal, StatusModal } from "@/shared/ui";
import {
  deleteTrigger,
  handleGetMetaFx,
  setError,
  setIsDeleteLoading,
  setIsDeleteOpened,
  setIsSuccessModal,
  setUserId,
  useContentStore,
} from "@/views/Content/model";
import { NotFoundPage } from "@/views/NotFound";

import { ContentImage } from "../lib/ui";
import * as S from "./style";

export const ContentPage: FC = () => {
  const {
    isDeleteLoading,
    isDeleteOpened,
    isLoading,
    userId,
    status,
    meta,
    error,
    isSuccessModal,
  } = useContentStore();
  console.log(status, meta);

  const recaptchaRef = useRef<null | ReCAPTCHA>(null);
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      handleGetMetaFx(params.id);
    }
  }, [params]);

  useEffect(() => {
    if (!isDeleteOpened) {
      recaptchaRef.current?.reset();
    }
  }, [isDeleteOpened]);

  const handleSubmit = () => {
    if (!userId) {
      setError("The field is required.");

      return;
    }

    setIsDeleteLoading(true);

    recaptchaRef.current
      ?.executeAsync()
      .then(deleteTrigger)
      .catch((err: Error) => {
        console.log(err);

        setError("You need to solve captcha");
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  };

  if (status === ContentStatus.NotFound) {
    return <NotFoundPage />;
  }

  return (
    <S.Root center={isLoading}>
      <StatusModal
        isOpened={isSuccessModal}
        handleClose={() => setIsSuccessModal(false)}
        status="success"
      >
        The screenshot has been deleted
      </StatusModal>
      <Modal
        isOpened={isDeleteOpened}
        handleClose={() => setIsDeleteOpened(false)}
        title={`Delete ${
          meta?.type === ContentType.Video ? "screen recording" : "screenshot"
        }?`}
        actions={
          <>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
              size="invisible"
              hl="en"
            />
            <Button
              stretch
              onClick={handleSubmit}
              disabled={isDeleteLoading || !!error}
            >
              Delete
            </Button>
            <Button
              theme="gray"
              onClick={() => setIsDeleteOpened(false)}
              disabled={isDeleteLoading}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Input
          value={userId}
          onChange={({ target }) => setUserId(target.value)}
          placeholder="Please provide user id, for example: 123-456-789"
          error={error}
        />
      </Modal>
      <ContentImage />
    </S.Root>
  );
};
