import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { useStore } from "effector-react";

import { api } from "@/api";
import { urls } from "@/api/urls";
import { ContentMeta, ContentStatus, ContentType } from "@/shared/lib/types";

import { ContentStore, DeleteFileParams } from "./types";

export const handleGetMetaFx = createEffect(async (id: string) => {
  const { data } = await api.meta.getFileMeta(id);

  return { ...data, id };
});

export const handleGetFileFx = createEffect(async ({ id }: ContentMeta) => {
  const { data } = await api.file.getFileById(id);

  return URL.createObjectURL(data);
});

const handleDeleteFile = createEffect(
  async ({ fileId, userId, captcha }: DeleteFileParams) =>
    api.delete.deleteFileById({ fileId, userId, captcha }),
);

const handleDownloadFileFx = createEffect(
  async ({ id, name }: { id: string; name: string }) => {
    const { data } = await api.file.getFileById(id);

    const url = URL.createObjectURL(data);
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", name);

    document.body.appendChild(link);

    link.click();

    link.parentNode?.removeChild(link);
  },
);

export const setUserId = createEvent<string>();
export const setIsDeleteOpened = createEvent<boolean>();
export const deleteTrigger = createEvent<string | null>();
export const setError = createEvent<string>();
export const setIsDeleteLoading = createEvent<boolean>();
export const setIsSuccessModal = createEvent<boolean>();
export const downloadFile = createEvent<void>();

export const $content = createStore<ContentStore>({
  contentUrl: "",
  isLoading: false,
  meta: null,
  status: null,
  isDeleteLoading: false,
  userId: "",
  error: "",
  isDeleteOpened: false,
  isSuccessModal: false,
  isDownloadLoading: false,
})
  .on(handleGetMetaFx, (state) => ({ ...state, isLoading: true }))
  .on(handleGetMetaFx.done, (state, { result: meta }) => ({
    ...state,
    meta,
    status: meta?.isDeleted ? ContentStatus.Deleted : null,
    contentUrl:
      meta.type.valueOf() === ContentType.Video
        ? `${process.env.REACT_APP_API_URL}${urls.fileById(meta?.id || "")}`
        : "",
  }))
  .on(handleDownloadFileFx, (state) => ({ ...state, isDownloadLoading: true }))
  .on([handleDownloadFileFx.fail, handleDownloadFileFx.done], (state) => ({
    ...state,
    isDownloadLoading: false,
  }))
  .on(handleGetMetaFx.fail, (state) => ({
    ...state,
    meta: null,
    status: ContentStatus.NotFound,
  }))
  .on([handleGetMetaFx.doneData, handleGetMetaFx.failData], (state) => ({
    ...state,
    isLoading: false,
  }))
  .on(handleDeleteFile, (state) => ({ ...state, isDeleteLoading: true }))
  .on(handleDeleteFile.done, (state) => ({
    ...state,
    isDeleteOpened: false,
    isSuccessModal: true,
    userId: "",
    error: "",
    meta: state.meta ? { ...state.meta, isDeleted: true } : null,
    status: ContentStatus.Deleted,
  }))
  .on(handleDeleteFile.fail, (state, { error }) => ({
    ...state,
    // @ts-ignore
    error: error?.response.data.error,
  }))
  .on([handleDeleteFile.doneData, handleDeleteFile.failData], (state) => ({
    ...state,
    isDeleteLoading: false,
  }))
  .on(setUserId, (state, userId) => ({
    ...state,
    userId,
    error: "",
  }))
  .on(setIsDeleteOpened, (state, isDeleteOpened) => ({
    ...state,
    isDeleteOpened,
    userId: "",
    error: "",
  }))
  .on(setError, (state, error) => ({ ...state, error }))
  .on(setIsDeleteLoading, (state, isDeleteLoading) => ({
    ...state,
    isDeleteLoading,
  }))
  .on(setIsSuccessModal, (state, isSuccessModal) => ({
    ...state,
    isSuccessModal,
  }))
  .on(handleGetFileFx, (state) => ({ ...state, isLoading: true }))
  .on(handleGetFileFx.doneData, (state, contentUrl) => ({
    ...state,
    contentUrl,
  }))
  .on(handleGetFileFx.failData, (state) => ({
    ...state,
    status: ContentStatus.NotFound,
  }))
  .on([handleGetFileFx.done, handleGetFileFx.fail], (state) => ({
    ...state,
    isLoading: false,
  }));

export const handleDeleteFileFx = attach({
  effect: handleDeleteFile,
  source: $content,
  mapParams: (captcha: string, { userId, meta }) => ({
    captcha,
    userId,
    fileId: meta?.id || "",
  }),
});

sample({
  clock: deleteTrigger,
  source: $content,
  filter: ({ userId }, captcha) => !!userId && !!captcha,
  fn: (_, captcha) => captcha as string,
  target: handleDeleteFileFx,
});

sample({
  clock: deleteTrigger,
  source: $content,
  filter: (_, captcha) => !captcha,
  fn: () => "You need to solve captcha",
  target: setError,
});

sample({
  clock: deleteTrigger,
  source: $content,
  filter: ({ userId }) => !userId,
  fn: () => "The field is required.",
  target: setError,
});

sample({
  clock: handleGetMetaFx.doneData,
  filter: (meta) => meta.type === ContentType.Image && !meta.isDeleted,
  target: handleGetFileFx,
});

sample({
  clock: downloadFile,
  source: $content,
  filter: ({ meta, contentUrl }) =>
    meta?.type === ContentType.Image && !!contentUrl,
  fn: ({ meta, contentUrl }) => {
    const link = document.createElement("a");

    link.href = contentUrl;
    link.setAttribute("download", meta?.fileName || "");

    document.body.appendChild(link);

    link.click();

    link.parentNode?.removeChild(link);
  },
});

sample({
  clock: downloadFile,
  source: $content,
  filter: ({ meta }) => meta?.type === ContentType.Video,
  fn: ({ meta }) => ({
    id: (meta as ContentMeta).id,
    name: (meta as ContentMeta).fileName,
  }),
  target: handleDownloadFileFx,
});

export const useContentStore = (): ContentStore => useStore($content);
