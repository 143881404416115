import { globalCss } from "@stitches/react";

export const setupFonts = globalCss({
  "@font-face": [
    {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontDisplay: "swap",
      fontWeight: 400,
      src: 'local("Rubik"), url("/assets/fonts/Rubik/Regular/RubikRegular.woff2") format("woff2"), url("/assets/fonts/Rubik/Regular/RubikRegular.woff") format("woff")',
    },
    {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontDisplay: "swap",
      fontWeight: 500,
      src: 'local("Rubik"), url("/assets/fonts/Rubik/Medium/RubikMedium.woff2") format("woff2"), url("/assets/fonts/Rubik/Medium/RubikMedium.woff") format("woff")',
    },
    {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontDisplay: "swap",
      fontWeight: 700,
      src: 'local("Rubik"), url("/assets/fonts/Rubik/Bold/RubikBold.woff2") format("woff2"), url("/assets/fonts/Rubik/Bold/RubikBold.woff") format("woff")',
    },
  ],
});
