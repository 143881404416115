import { NavLink } from "react-router-dom";

import { styled } from "@/shared/lib/styles";

export const Header = styled("header", {
  width: "100%",
  height: 80,
  padding: "30px 0",

  backgroundColor: "$white",
  boxSizing: "border-box",

  "@wideMobile": {
    padding: "20px 0",
    height: 80,
  },

  "@wideTablet": {
    height: 135,
    padding: "40px 0",

    filter: "drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1))",
  },
});

export const Container = styled("div", {
  display: "flex",

  container: "",
});

export const Link = styled(NavLink, {
  textDecoration: "none",
});

export const Logo = styled("img", {
  width: 32,

  userSelect: "none",

  "@wideMobile": {
    width: 200,
  },

  "@wideTablet": {
    width: 280,
  },
});
