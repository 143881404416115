import { styled } from "@/shared/lib/styles";

export const Root = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignSelf: "stretch",

  paddingBottom: 100,

  "@wideMobile": {
    display: "block",
    alignSelf: "center",

    padding: "10px 0 40px",

    backgroundSize: "contain",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundImage: "url('/assets/img/not-found-bg.png')",
  },

  "@wideTablet": {
    paddingBottom: 30,
  },
});

export const Content = styled("div");

export const Title = styled("h3", {
  margin: "0 0 10px",

  color: "$pink",
  fontSize: "$notFoundTitle",
  fontWeight: "$bold",
  textAlign: "center",
  lineHeight: "118px",

  "@wideMobile": {
    marginBottom: 0,
  },
});

export const Subtitle = styled("h1", {
  margin: "0 0 10px",

  fontSize: "$default",
  fontWeight: "$bold",
  lineHeight: "28px",
  textAlign: "center",

  "@wideTablet": {
    marginBottom: 15,

    fontSize: "$defaultLarge",
    lineHeight: "43px",
  },
});

export const Description = styled("p", {
  margin: "0",

  fontSize: "$small",
  fontWeight: "$medium",
  lineHeight: "180%",
  textAlign: "center",

  "@wideMobile": {
    marginBottom: 30,
  },

  "@wideTablet": {
    fontSize: "$default",
    lineHeight: "28px",
  },
});

export const Footer = styled("div", {
  display: "flex",
  justifyContent: "center",
});
