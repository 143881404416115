import { styled } from "@/shared/lib/styles";

export const Root = styled("div", {
  variants: {
    center: {
      true: {
        alignSelf: "center",
      },
    },
  },
});
