export enum ContentType {
  Image = 0,
  Video = 1,
}

export enum ContentStatus {
  NotFound,
  Deleted,
}

export interface ContentMeta {
  type: ContentType;
  fileName: string;
  isUploaded: boolean;
  isDeleted: boolean;
  id: string;
}
