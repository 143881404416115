import { FC, useMemo, useState } from "react";

import { DownloadIcon, TrashIcon, WarningRoundIcon } from "@/shared/lib/icons";
import { ContentStatus, ContentType } from "@/shared/lib/types";
import { Button, Modal, Video } from "@/shared/ui";
import {
  downloadFile,
  setIsDeleteOpened,
  useContentStore,
} from "@/views/Content/model";

import * as S from "./style";

export const ContentImage: FC = () => {
  const [isPreviewModal, setIsPreviewModal] = useState<boolean>(false);
  const { meta, isLoading, status, contentUrl, isDownloadLoading } =
    useContentStore();

  const isDeleted = status === ContentStatus.Deleted.valueOf();

  const handleClickDelete = () => {
    setIsDeleteOpened(true);
  };

  const imageContent = useMemo(() => {
    if (!contentUrl || typeof meta?.type === "object") {
      return null;
    }

    if (meta?.type.valueOf() === ContentType.Image) {
      return (
        <S.ImageButton onClick={() => setIsPreviewModal(true)}>
          <S.Image src={contentUrl} alt="" />
        </S.ImageButton>
      );
    }

    return (
      <Video
        options={{}}
        source={{
          type: "video",
          sources: [
            {
              src: contentUrl,
            },
          ],
        }}
      />
      // <ReactPlayer
      //   url={`${process.env.REACT_APP_API_URL}${urls.fileById(meta?.id || "")}`}
      // />
    );
  }, [contentUrl, meta]);

  const pageContent = useMemo(() => {
    const contentText = "content";

    if (isLoading) {
      return (
        <>
          <S.ActionIcon>
            <S.ActionImg src="/assets/icons/download.svg" alt="" />
          </S.ActionIcon>
          <S.ActionTitle>
            The {contentText} is loading.
            <br /> Please wait
          </S.ActionTitle>
        </>
      );
    }

    if (isDeleted) {
      return (
        <>
          <S.ActionIcon>
            <WarningRoundIcon />
          </S.ActionIcon>
          <S.ActionTitle>
            Oops, it looks like the <br />
            {contentText} has expired or it has <br />
            been deleted
          </S.ActionTitle>
        </>
      );
    }

    return (
      <>
        <S.ImageContent>{imageContent}</S.ImageContent>
        <S.Footer>
          <Button
            size="medium"
            theme="gradient"
            onClick={() => downloadFile()}
            disabled={isDownloadLoading}
          >
            <DownloadIcon />
            Download
          </Button>
          <Button size="medium" theme="gray" onClick={handleClickDelete}>
            <TrashIcon />
            Delete
          </Button>
        </S.Footer>
      </>
    );
  }, [isLoading, imageContent, meta?.type, isDeleted, isDownloadLoading]);

  return (
    <S.Root>
      <Modal
        isOpened={isPreviewModal}
        handleClose={() => setIsPreviewModal(false)}
      >
        <S.PreviewImage src={contentUrl} alt="" />
      </Modal>
      {pageContent}
    </S.Root>
  );
};
