import { FC } from "react";

export const TrashIcon: FC = () => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="11" height="2" rx="0.4" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 4.4C11 4.17909 10.8209 4 10.6 4H0.399999C0.179085 4 -1.39978e-06 4.17909 -1.39978e-06 4.4V13.6C-1.39978e-06 13.8209 0.179084 14 0.399998 14H10.6C10.8209 14 11 13.8209 11 13.6V4.4ZM3 6.4C3 6.17909 2.82091 6 2.6 6H2.4C2.17908 6 2 6.17909 2 6.4V11.6C2 11.8209 2.17908 12 2.4 12H2.6C2.82091 12 3 11.8209 3 11.6V6.4ZM5 6.4C5 6.17909 5.17908 6 5.4 6H5.6C5.82091 6 6 6.17909 6 6.4V11.6C6 11.8209 5.82091 12 5.6 12H5.4C5.17908 12 5 11.8209 5 11.6V6.4ZM9 6.4C9 6.17909 8.82091 6 8.6 6H8.4C8.17908 6 8 6.17909 8 6.4V11.6C8 11.8209 8.17908 12 8.4 12H8.6C8.82091 12 9 11.8209 9 11.6V6.4Z"
      fill="white"
    />
  </svg>
);
