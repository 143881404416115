import { FC, useEffect, useRef } from "react";

import * as S from "./style";
import { ModalProps } from "./types";

export const Modal: FC<ModalProps> = ({
  isOpened,
  handleClose,
  title,
  children,
  actions,
}) => {
  const contentRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (isOpened) {
      document.body.classList.add("overflow-hidden");

      return;
    }

    document.body.classList.remove("overflow-hidden");
  }, [isOpened]);

  return (
    <S.Modal
      isOpened={isOpened}
      onClick={({ target }) =>
        contentRef.current &&
        !contentRef.current?.contains(target as Node) &&
        handleClose()
      }
    >
      <S.ContentWrapper>
        <S.Content ref={contentRef} isOpened={isOpened}>
          {title && <S.Title>{title}</S.Title>}
          {children && <S.ModalContent>{children}</S.ModalContent>}
          {actions && <S.Footer>{actions}</S.Footer>}
        </S.Content>
      </S.ContentWrapper>
    </S.Modal>
  );
};
