import { FC } from "react";

export const DownloadIcon: FC = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3.5" width="5" height="6" rx="0.4" fill="white" />
    <rect x="0.5" y="12" width="11" height="2" rx="0.4" fill="white" />
    <path
      d="M5.68672 9.60536C5.84686 9.80709 6.15314 9.80709 6.31328 9.60536L10.2482 4.6487C10.4563 4.38656 10.2696 4 9.93488 4H2.06512C1.73042 4 1.54373 4.38656 1.75184 4.6487L5.68672 9.60536Z"
      fill="white"
    />
  </svg>
);
