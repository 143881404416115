import { styled } from "@/shared/lib/styles";

export const Video = styled("div", {
  display: "flex",
  justifyContent: "center",

  width: "100%",

  "& > .plyr": {
    width: "100% !important",
    maxWidth: 850,
    maxHeight: 600,
    margin: "0 auto",
  },
});
