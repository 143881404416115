import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { routes } from "./routes";

export const Router: FC = () => (
  <Routes>
    {routes.map((route) => (
      <Route key={route.path} path={route.path} element={route.element}>
        {route.children}
      </Route>
    ))}
  </Routes>
);
